import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SuccessView from '../views/SuccessView.vue'
import userAgreement from '../views/userAgreement.vue'
import privacyPage from '../views/privacyPage.vue'
import phoneBillSuccess from '../views/phoneBillSuccess.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: userAgreement
  },
  {
    path: '/privacyPage',
    name: 'privacyPage',
    component: privacyPage
  },
  {
    path: '/phs',
    name: 'phoneBillSuccess',
    component: phoneBillSuccess
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
